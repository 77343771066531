.social .socBox {
  padding: 10px 20px;
  margin-bottom: 10px;
  color: #fff;
}
.social .socBox i {
  margin-right: 20px;
}
.social .socBox span {
  font-weight: 500;
}
.social .socBox:nth-child(1) {
  background-color: #3b5998;
}
.social .socBox:nth-child(2) {
  background-color: #bd081c;
}
.social .socBox:nth-child(3) {
  background-color: #1da1f2;
}
.social .socBox:nth-child(4) {
  background-color: #c32aa3;
}
.social .socBox:nth-child(5) {
  background-color: #ff0000;
}
/*------subscribe--------*/
.subscribe {
  border: 1px solid black;
  padding: 20px;
  text-align: center;
}
.subscribe h1 {
  text-align: left;
  font-size: 20px;
}
.subscribe input {
  width: 100%;
  padding: 10px;
}
.subscribe button {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #44a3a5;
  color: #fff;
  font-weight: 600;
  border: none;
}
/*------subscribe--------*/
.banner {
  margin: 40px 0;
}
.catgorys .category1 {
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
}
/*----------gallery----------*/
.gallery .img {
  width: 315px;
  height: 200px;
}
.gallery img {
  width: 315px;
  height: 200px;
  object-fit: cover;
}
/*----------gallery----------*/
@media screen and (max-width: 768px) {
  .gallery img,
  .gallery .img {
    width: 100%;
    height: 400px;
  }
}
