.head {
  cursor: pointer;
}
.head .logo img {
  width: 250px;
}
header {
  background-color: #44a3a5;
  color: #fff;
}
header li {
  margin-right: 20px;
  transition: 0.5s;
}
header li:hover {
  color: crimson;
  cursor: pointer;
}
header a {
  color: #fff;
}
.barIcon {
  font-size: 25px;
  background: none;
  color: #fff;
  display: none;
  border: none;
}
@media screen and (max-width: 768px) {
  .head .ad {
    display: none;
  }
  header .icon {
    display: none;
  }
  header .flex {
    display: none;
  }
  .barIcon {
    display: block;
  }
  header .navbar {
    position: absolute;
    display: block;
    background-color: #44a3a5;
    left: 0;
    top: 30%;
    transition: 0.5s;
    width: 100%;
    z-index: 5;
    padding: 10px;
  }
  header .navbar {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  header ul li {
    margin-bottom: 20px;
  }
}
